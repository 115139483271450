<template>
  <div>
    <div class="fd-img-wrap">
      <img style="width:100%" src="../assets/images/hz.png" alt="">
    </div>
    <div class="fd-hz-content-wrap">
      <img src="../assets/images/yh.png" alt="">
    </div>
  </div>
</template>
<script>
export default {
  name: 'hzhb',
  data () {
    return {}
  },
  methods:{},
  mounted () {}
}
</script>
<style lang="stylus" scoped>
.fd-hz-content-wrap
  width 1200px
  margin 0 auto 
  padding 80px 0
  text-align center
</style>


